
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import TableMixin from '@/mixins/TableMixin.vue'
import moment from 'moment'
import AccountPartnerForm from '@/views/Account/Partners/components/AccountPartnerForm.vue'
import Partners from '@/apis/Partners'
import _ from 'lodash'
import { PartnerCategory, UserStatusId } from '@/typings/AdminTyping'
import { DealershipStatusId } from '@/typings/DealershipTyping'
import { Http } from '../../../apis/Http'

@Component({
  name: 'EditPartner',
  components: {
    AccountPartnerForm
  }
})

export default class EditPartner extends Mixins(TableMixin) {
  simpleTable = true
  perPage = 1000

  mounted() {
    // @ts-ignore
    this.categoryId = this.$router.currentRoute.params.categoryId
    // @ts-ignore
    this.partnerId = this.$router.currentRoute.params.id
  }

  created() {
    // @ts-ignore
    this.categoryId = this.$router.currentRoute.params.categoryId
    // @ts-ignore
    this.partnerId = this.$router.currentRoute.params.id
    this.fetchDetailsForUpdatePartner(
      this.partnerId,
      this.categoryId
    )
  }

  categoryId = null
  partnerId = null
  brands = []
  networks = []
  cities = []
  regions = []
  statuses = []
  dealershipStatuses = []
  partnerDetails = {}

  dealershipsList = {
    headers: [
      {
        text: 'Название',
        align: 'start',
        value: 'name'
      },
      {
        text: 'Адрес',
        align: 'start',
        value: 'email'
      },
      {
        text: 'Статус',
        align: 'start',
        value: 'status'
      },
      {
        text: 'Редактировать',
        align: 'start',
        value: 'edit'
      }
    ],
    items: []
  }

  usersList = {
    headers: [
      {
        text: 'Пользователь',
        align: 'start',
        value: 'name'
      },
      {
        text: 'Email',
        align: 'start',
        value: 'email'
      },
      {
        text: 'Телефон',
        align: 'start',
        value: 'phone'
      },
      {
        text: 'Роль',
        align: 'start',
        value: 'role'
      },
      {
        text: 'Статус',
        align: 'start',
        value: 'status'
      }
    ],
    items: []
  }

  requestsList = {
    headers: [
      {
        text: 'Номер',
        align: 'start',
        value: 'id'
      },
      {
        text: 'Дата запроса',
        align: 'start',
        value: 'dateRequest'
      },
      {
        text: 'Дата ответа',
        align: 'start',
        value: 'dateResponse'
      },
      {
        text: 'Транспортное средство',
        align: 'start',
        value: 'car'
      },
      {
        text: 'Лизинговая компания',
        align: 'start',
        value: 'leasingCompany'
      }
    ],
    items: []
  }

  get isNetwork() {
    // @ts-ignore
    return parseInt(this.categoryId) === PartnerCategory.DealershipNetwork
  }

  get isDealership() {
    // @ts-ignore
    return parseInt(this.categoryId) === PartnerCategory.Dealership
  }

  get isLeasingCompany() {
    // @ts-ignore
    return parseInt(this.categoryId) === PartnerCategory.LeasingCompany
  }

  fetchDetailsForUpdatePartner(partnerId, categoryId) {
    Partners.fetchDetailsForUpdatePartner(partnerId, categoryId)
      .then(({ data }) => {
        console.log(data)
        this.brands = data.brands
        this.networks = data.networks
        this.cities = data.cities
        this.regions = data.regions
        this.statuses = data.statuses
        this.dealershipStatuses = data.dealershipStatuses
        this.partnerDetails = data.partnerDetails
        this.dealershipsList.items = data.partnerDetails.dealershipsList
        this.usersList.items = this.updateUserList(data.users, data.userStatuses, data.userRoles)
        this.requestsList.items = data.requests
        this.categoryId = categoryId
        this.partnerId = partnerId
      })
      .catch((errors) => {
        console.log(errors)
      })
  }

  updatePartner() {
    Partners.updatePartner(this.partnerDetails, this.partnerId)
      .then(({ data }) => {
        if (data.success === true) {
          // @ts-ignore
          this.showSuccessSystemNotification(data.msg)
          this.$router.push({ name: 'AccountPartners' })
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(data.msg)
        }
      })
      .catch(() => {
        // @ts-ignore
        this.showErrorSystemNotification('Заполните поля для сохранения')
      })
  }

  statusName(status) {
    // @ts-ignore
    const index = _.findIndex(this.dealershipStatuses, { id: status })
    // @ts-ignore
    return this.dealershipStatuses[index].name
  }

  isActive(status) {
    return status === DealershipStatusId.Active
  }

  formatDate(date: Date): string {
    return moment(date).format('LLL')
  }

  updateUser(user) {
    Http.post('/admin/user/update', user).then(res => {
      alert('Данные о пользователе обновлены')
    })
  }

  updateUserList(users, statuses, roles) {
    users.forEach(function (user) {
      console.log(user.user_status_id)
      const userStatus = _.find(statuses, ['id', user.user_status_id])
      user.status = userStatus
      user.status.isActive = (parseInt(user.status.id) === UserStatusId.Active)
      const userRole = _.find(roles, ['id', user.user_role_id])
      user.role = userRole
    }, statuses, roles)
    return users
  }
}
